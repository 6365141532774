import React, {useEffect} from "react"
import { InlineWidget } from "react-calendly"
import useStore from "@/store/index"

import { Layout } from "@/layout"
import { Box, SEO, Heading, Text } from "@/components"

export default function Application() {
  const user = useStore(state => state.user)

    useEffect(() => {
      const script = document.createElement('script');
    
      script.src = "https://cdn.oncehub.com/mergedjs/so.js";
      script.async = true;
    
      document.body.appendChild(script);
    
      return () => {
        document.body.removeChild(script);
      }
    }, []);
  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="Schedule Call"
        description="Let's talk about your guitar playing"
        noindex={true}
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["0.8rem", null, null, "0"]}
      >
        <Box width={["100%", null, null, 5 / 12]} textAlign="center" mx="auto">
          <Text variant="subhead" mt="4rem">
            STEP 2 of 3
          </Text>
          <Heading
            level="1"
            mb="1rem"
            mt="3rem"
            px={["2rem", "2rem", "2rem", "2rem"]}
          >
            Schedule a Call
          </Heading>
          <Text mb="0rem" mt="1rem">
            Please wait for the calendar to load
          </Text>
        </Box>
        <Box
          width={["90%", null, null, 6 / 12]}
          mx="auto"
          mt={["2rem", null, null, "1rem"]}
        >
          <div id="SOIDIV_Sonora" data-so-page="Sonora" data-height="550" data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;" data-psz="00"></div>
        </Box>
      </Box>
    </Layout>
  )
}
